function intoAnimation () {
  removeIntroMask(getDelay);

  function removeIntroMask(callback) {
    let introBlack = document.getElementById("intro-mask");
    callback(introBlack);
  }

  //the function getDelay gets the delay-time from the elements animation-duration value
  function getDelay(elName) {
    let getStyle = window.getComputedStyle(elName);
    let rawValue = getStyle.getPropertyValue('animation-duration');
    let seconds = rawValue.substring(0, rawValue.length - 1);
    let delay = seconds * 1000;
    let sectionsEl = document.getElementById('sections');
    sectionsEl.style.overflowY = 'hidden';
    setTimeout(() => {
      elName.remove();
      sectionsEl.style.overflowY = 'scroll';
    }, delay);
  }
}
intoAnimation();